























































import {
  computed,
  defineComponent,
  useContext,
  watch,
  toRefs,
  onMounted,
  ssrRef
} from '@nuxtjs/composition-api';
import type { Ref } from '@nuxtjs/composition-api';
import { reviewGetters, productGetters } from '@vsf-enterprise/commercetools';
import { onSSR, useVSFContext, Logger } from '@vue-storefront/core';
import { CacheTagPrefix, useCache } from '@vue-storefront/cache';
import { AgnosticAttribute } from '@vue-storefront/core/lib/src/types';
import { generateMetaTitleWithCTFormat } from '~/helpers/commercetools/meta/generateMetaTitle';
import {
  useStock,
  useI18n,
  useProductExtended,
  useProductRelated,
  useCookies,
  useMetaExtended,
  useSeoRedirection,
  usePageViewEvent,
  useAddToCart,
  useReviewExtended,
  useIntegrations
} from '~/composables';
import { productFilterForAddingToCart } from '~/helpers/filters/productFilters';
import { getMetaDescription, isPdpPageIndexed } from '~/helpers/product/getMetaInfo/getMetaInfo';
import MissingTranslationNotification from '~/components/molecules/Product/MissingTranslationNotification.vue';
import createAccessoriesProductSearchParams from '~/helpers/product/createAccessoriesProductSearchParams';
import { SCHEMA_ITEM_TYPE } from '~/constants/seo';
import ProductDetailsSection from '~/components/organisms/Product/ProductDetailsSection/ProductDetailsSection.vue';
import ProductDetailsSectionSkeleton from '~/components/molecules/PdpSkeletons/ProductDetailsSectionSkeleton.vue';
import TrustedBy from '~/components/molecules/Product/TrustedBy.vue';
import ProductAnchorSections from '~/components/organisms/Product/ProductAnchorSections.vue';
import { STATUS_RESOURCE_NOT_FOUND } from '~/constants/http';
import { PRODUCT_ATTRIBUTES } from '~/constants/products';
import { getLocalizedSlugsForHreflangs } from '~/helpers/product/getLocalizedSlugsForHreflangs';
import { isSecondHandProduct } from '~/helpers/product/getProductInfo/getProductInfo';

export default defineComponent({
  name: 'Pdp',
  components: {
    ProductAnchorSections,
    ProductBreadcrumbs: () => import(/* webpackChunkName: "ProductBreadcrumbs" */
      '~/components/molecules/Product/ProductBreadcrumbs.vue'),
    MissingTranslationNotification,
    ProductDetailsSection,
    ProductDetailsSectionSkeleton,
    TrustedBy,
    ErrorLayout: () => import(/* webpackChunkName: "ErrorLayout" */
      '~/layouts/error.vue')
  },
  transition: 'fade',
  props: {
    slug: {
      type: String,
      required: true
    }
  },
  setup(props: { slug: string }) {
    const { slug }: {slug: Ref<string>} = toRefs(props);
    const { i18n } = useVSFContext();
    const { $tagManager, $cia } = useIntegrations();
    const { posthogDistinctId, bloomreachCookie, addBloomreachCookieChangeListener } = useCookies();
    const { res } = useContext();
    const { seoRedirection } = useSeoRedirection();
    const { callPageViewEvent } = usePageViewEvent();
    const {
      search: searchProduct,
      loading: productLoading,
      masterProduct: product,
      masterProductLocalisedAttributes: attributesLocalized
    } = useProductExtended(slug.value);
    const { getStocks } = useStock(slug.value);
    const {
      productsInStock: relatedAccessories,
      search: searchRelatedAccessories,
      loading: relatedAccessoriesLoading
    } = useProductRelated(`${slug.value}-relatedAccessories`);
    const {
      productsInStock: parentsForAccessory,
      search: searchParentsForAccessory,
      loading: parentsForAccessoryLoading
    } = useProductRelated(`${slug.value}-parentForAccessory`);
    const {
      availableQuantity,
      isQuantitySelectionPossible,
      stock
    } = useAddToCart(slug.value, productGetters.getSku(product.value));
    const { addTags } = useCache();
    const isReadytoSendViewItemEvent = computed(() =>
      product.value && !productLoading.value && !!bloomreachCookie.value
    );
    const productAdjusted = computed(() => productFilterForAddingToCart(product.value));
    const productId = computed(() => productGetters.getId(product.value));
    const { currencyCode, languageAndCountry } = useI18n();
    const {
      reviews: productReviews,
      search: searchReviews
    } = useReviewExtended(productId);
    const error = ssrRef(null, 'error-pdp');

    const relatedAccessoriesList = computed(
      () => productGetters.getFiltered(relatedAccessories.value, { master: true })
    );

    const shouldShowRelatedAccessories = computed(() =>
      relatedAccessoriesList.value &&
      relatedAccessoriesList.value.length &&
      !isSecondHandProduct(product.value)
    );

    onSSR(async () => {
      error.value = null;

      const pdpSearchParams = {
        slug: slug.value
      };

      await searchProduct(pdpSearchParams);

      if (product.value) {
        const searchPromises = [];
        searchPromises.push(getStocks({ sku: product.value.sku }));
        searchPromises.push(searchReviews());

        const accessoriesList = productGetters.getAttributes(
          product.value,
          [PRODUCT_ATTRIBUTES.RELATED_ACCESSORIES]
        )?.relatedAccessories as AgnosticAttribute;
        if (accessoriesList) {
          const accessoriesParams = createAccessoriesProductSearchParams(accessoriesList, languageAndCountry.value);
          searchPromises.push(searchRelatedAccessories(accessoriesParams));
        }
        const parentProductsForAccessory = productGetters.getAttributes(product.value,
          [PRODUCT_ATTRIBUTES.PARENT_PRODUCTS_FOR_ACCESSORY])?.parentProductsForAccessory as AgnosticAttribute;
        if (parentProductsForAccessory) {
          const parentProductsForAccessoryParams =
          createAccessoriesProductSearchParams(parentProductsForAccessory, languageAndCountry.value);
          searchPromises.push(searchParentsForAccessory(parentProductsForAccessoryParams));
        }
        // await is necessary here to load the correct product info
        await Promise.allSettled(searchPromises);
        addTags([
          { prefix: CacheTagPrefix.View, value: 'pdp' },
          { prefix: CacheTagPrefix.Product, value: slug.value }
        ]);
      } else {
        // await is neccessary here else we get error(Cannot set headers after they are sent to client)
        await seoRedirection();
        console.log(`404 statuscode for slug : ${slug.value}`);

        const statusCode = STATUS_RESOURCE_NOT_FOUND;
        error.value = { statusCode };
        if (res) {
          res.statusCode = statusCode;
        }
      }
    });

    const reviews = computed(() => reviewGetters.getItems(productReviews.value));
    const topReview = computed(() => reviews.value.find(review => review.text));

    const defaultMetaDescription =
        i18n.t('SEO meta description PDP', { productTitle: productGetters.getName(product.value) });
    useMetaExtended({
      metaTitle: computed(() => generateMetaTitleWithCTFormat(productGetters.getName(product.value), i18n)),
      isPageIndexed: computed(() => isPdpPageIndexed(product.value)),
      metaDescription: computed(() => getMetaDescription(product.value) || defaultMetaDescription),
      slug,
      localisedSlugs: computed(() => getLocalizedSlugsForHreflangs(product.value)),
      isPdp: true
    });

    addBloomreachCookieChangeListener();
    watch(() => (isReadytoSendViewItemEvent.value), (readytoSendViewItemEvent) => {
      if (readytoSendViewItemEvent) {
        try {
          $cia.event.viewItem(product.value.sku, posthogDistinctId);
        } catch (error) {
          Logger.error(`cia|viewItem error: ${error}`);
        }
      }
    }, { immediate: true });

    watch(() => (productLoading.value), (productLoading) => {
      if (!productLoading && product.value) {
        $tagManager.events.triggerViewProductTags(product.value, currencyCode.value, isQuantitySelectionPossible.value);
      }
    }, { immediate: true });

    onMounted(() => {
      callPageViewEvent();
    });

    return {
      product,
      productAdjusted,
      attributesLocalized,
      reviews,
      topReview,
      relatedAccessoriesList,
      parentsForAccessory: computed(() => productGetters.getFiltered(parentsForAccessory.value, { master: true })),
      relatedAccessoriesLoading,
      parentsForAccessoryLoading,
      SCHEMA_ITEM_TYPE,
      availableQuantity,
      shouldShowRelatedAccessories,
      stock,
      error
    };
  },
  /** empty head needed to activate useMeta composable. */
  head: {}
});

