










































import { computed, defineComponent, onMounted, ref, useContext, ssrRef, useRoute } from '@nuxtjs/composition-api';
import type { HitResultItem } from 'vue-instantsearch';
import { onSSR, sharedRef, useVSFContext } from '@vue-storefront/core';
import { useCache, CacheTagPrefix } from '@vue-storefront/cache';
import AlgoliaContainer from '~/components/organisms/Search/AlgoliaContainer.vue';
import {
  useCategories,
  useMetaExtended,
  useSeoRedirection,
  usePageViewEvent,
  useCategoryPageTitle,
  useCategoryTree
} from '~/composables';
import PageTitle from '~/components/molecules/PageTitle.vue';
import MissingTranslationNotification from '~/components/molecules/Product/MissingTranslationNotification.vue';
import DisplayHtmlDangerously from '~/components/atoms/DisplayHtmlDangerously/DisplayHtmlDangerously.vue';
import { useI18n } from '~/composables/useI18n';
import BlogSlider from '~/components/molecules/Blog/BlogSlider.vue';
import { STATUS_RESOURCE_NOT_FOUND } from '~/constants/http';
import { getAlgoliaCategoryFilter } from '~/helpers/category/getAlgoliaCategoryFilter';
import { getRelatedTrees } from '~/helpers/category/getRelatedTrees';
import { RelatedTree } from '~/types/category/RelatedCategory';
import { hasOnlyWhitelistedParams } from '~/helpers/seo/whitelistedParameters';

export default defineComponent({
  name: 'SearchCategory',
  components: {
    AlgoliaContainer,
    MissingTranslationNotification,
    PageTitle,
    DisplayHtmlDangerously,
    BlogSlider,
    RelatedCategoryTrees: () => import(/* webpackChunkName: "RelatedCategoryTrees" */
      '~/components/molecules/Category/RelatedCategoryTrees/RelatedCategoryTrees.vue'
    ),
    ErrorLayout: () => import(/* webpackChunkName: "ErrorLayout" */
      '~/layouts/error.vue')
  },
  props: {
    categorySlug: {
      type: String,
      required: true
    }
  },
  setup(props) {
    const { res } = useContext();
    const route = useRoute();
    const { callPageViewEvent } = usePageViewEvent();
    const {
      categories,
      currentCategory,
      triggerViewCategoryEvent,
      searchWithDefaultParameters,
      isNewCategoryTree,
      getters: {
        currentCategorySlugAllLocales,
        currentCategoryMetaDescription,
        currentCategorySEODescription,
        currentCategoryBlogPosts
      }
    } = useCategories();
    const { categoryTree } = useCategoryTree();

    const { addTags } = useCache();
    const {
      languageAndCountry,
      isDefaultLanguageAndCountry,
      buildLanguagePrefixedUrlBasedOnActiveLanguage
    } = useI18n();
    const formattedCategorySEODescription = computed(() =>
      isDefaultLanguageAndCountry.value
        ? currentCategorySEODescription.value
        : currentCategorySEODescription.value?.replace(
          /href="\//g, `href="${buildLanguagePrefixedUrlBasedOnActiveLanguage('/')}`)
    );

    const { i18n } = useVSFContext();
    const { seoRedirection } = useSeoRedirection();
    const algoliaCategoryFilter = sharedRef('', 'algolia-category-filter');

    const onProductListingShown = (products: HitResultItem[]) => {
      triggerViewCategoryEvent(products, props.categorySlug);
    };

    const currentPage = ref(1);
    const onPageChanged = (newPage: number) => {
      currentPage.value = newPage;
    };

    const { generateActiveCategoryName, generateMetaTitle } = useCategoryPageTitle(currentPage);

    const activeCategory = computed(() => generateActiveCategoryName(algoliaCategoryFilter.value));

    const relatedTrees = ssrRef<RelatedTree[]>([]);

    const setAlgoliaCategoryFilter = async () => {
      await searchWithDefaultParameters(props.categorySlug);
      relatedTrees.value = getRelatedTrees(currentCategory.value, languageAndCountry.value);

      algoliaCategoryFilter.value = getAlgoliaCategoryFilter({
        categorySlug: props.categorySlug,
        categories: categories.value,
        categoryTree: categoryTree.value
      });
    };

    const error = ssrRef(null);

    onSSR(async () => {
      error.value = null;
      await setAlgoliaCategoryFilter();

      const page = Number(route.value.query?.page) || 1;
      currentPage.value = page;

      // check if valid category page
      if (algoliaCategoryFilter.value) {
        addTags([
          { prefix: CacheTagPrefix.View, value: 'searchcategory' },
          { prefix: CacheTagPrefix.Block, value: props.categorySlug }
        ]);
        return;
      }
      // check if a redirection exists for invalid slug
      // await is necessary here else we get error(Cannot set headers after they are sent to client)
      const redirectHappened = await seoRedirection();
      if (redirectHappened) {
        return;
      }

      // set error if no redirection exists for invalid slug
      const statusCode = STATUS_RESOURCE_NOT_FOUND;
      error.value = { statusCode };
      if (res) {
        res.statusCode = statusCode;
      }
    });

    const defaultMetaDescription = i18n.t(
      'SEO Category meta description', { categoryName: activeCategory.value }
    );

    const shouldBeIndexed = computed(() => hasOnlyWhitelistedParams(route.value.query));

    const metaTitle = computed(() => generateMetaTitle(activeCategory.value, error.value?.statusCode));

    useMetaExtended({
      metaTitle,
      isPageIndexed: shouldBeIndexed,
      metaDescription: computed(() => currentCategoryMetaDescription.value || defaultMetaDescription),
      slug: ref(props.categorySlug),
      localisedSlugs: currentCategorySlugAllLocales
    });

    const getPageCategory = () => {
      if (error.value?.statusCode) return 'page-not-found';
      if (isNewCategoryTree.value) return 'newCategoryTree';
      return 'category';
    };

    onMounted(() => {
      callPageViewEvent({
        pageCategory: getPageCategory()
      });
    });

    return {
      relatedTrees,
      algoliaCategoryFilter,
      onProductListingShown,
      onPageChanged,
      activeCategory,
      formattedCategorySEODescription,
      currentCategoryMetaDescription,
      languageAndCountry,
      currentCategoryBlogPosts,
      error
    };
  },
  /** empty head needed to activate useMeta composable. */
  head: {}
});
