

























import { defineComponent, computed } from '@vue/composition-api';
import type { ProductVariant } from '@vsf-enterprise/commercetools-types';
import type { PropType } from '@nuxtjs/composition-api';
import LazyHydrate from 'vue-lazy-hydration';
import BrandImage from '~/components/molecules/Product/Brand/BrandImage/BrandImage.vue';
import type { ProductProcessedAttributes } from '~/types/product/attribute/ProductProcessedAttributes';
import { getBrandInfo } from '~/helpers/brand';
import getProductAttribute from '~/helpers/product/getProductAttribute/getProductAttribute';
import { PRODUCT_ATTRIBUTES } from '~/constants/products';

export default defineComponent({
  name: 'ProductQuickDetails',
  components: {
    LazyHydrate,
    BrandImage,
    TrustAccordion: () => import(/* webpackChunkName: "TrustAccordion" */
      '~/components/organisms/Product/TrustAccordion/TrustAccordion.vue'),
    EnergyRating: () => import(/* webpackChunkName: "EnergyRating" */
      '~/components/molecules/Product/EnergyRating.vue')
  },
  props: {
    product: {
      type: Object as PropType<ProductVariant>,
      required: true
    },
    attributesLocalized: {
      type: Object as PropType<ProductProcessedAttributes>,
      required: true
    },
    shouldShowRelatedAccessories: {
      type: Boolean,
      required: true
    }
  },
  setup(props) {
    const brandInfo = computed(() => getBrandInfo(props.attributesLocalized?.brand) || {});
    const hasEnergyRating = computed(() => props.attributesLocalized?.energyRating?.rating);
    const manual = computed(() => getProductAttribute(props.product, PRODUCT_ATTRIBUTES.MANUAL_URL));
    const energyRatingLabel = computed(() =>
      getProductAttribute(props.product, PRODUCT_ATTRIBUTES.ENERGY_RATING_LABEL)
    );
    const energyRatingNestedLabel = computed(() =>
      getProductAttribute(props.product, PRODUCT_ATTRIBUTES.ENERGY_RATING_NESTED_LABEL)
    );

    return {
      brandInfo,
      hasEnergyRating,
      manual,
      energyRatingLabel,
      energyRatingNestedLabel
    };
  }
});
